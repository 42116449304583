import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { dashboardPageUrl, mainUrl } from "../costants";
import CustomTag from "../../custom/CustomTag";
import { Link, useNavigate } from "react-router-dom";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import { darkColor, formatDate } from "../../custom/utils";
import { getAlarms, getDevices } from "../../../api/services/deviceService";
import CustomLoading from "../../custom/CustomLoading";

type AlarmProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  admin: boolean;
};

const Alarm: React.FC<AlarmProps> = ({ setBreadcrumb, admin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link key="1" to={mainUrl} style={{ color: "#ffffff" }}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("alarmsHistory")}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [alarms, setAlarms] = useState<any[]>([]);
  const [values, setValues] = useState({
    start_date: formatDate(
      new Date(new Date().setDate(new Date().getDate() - 6))
    ),
    end_date: formatDate(new Date()),
  });

  const handleSubmit = () => {
    getAlarms(
      new Date(values.start_date).toISOString(),
      new Date(values.end_date).toISOString()
    ).then((res: any) => {
      if (res && res.alarms) {
        const updatedAlarms = res.alarms.map((alarm: any) => {
          if (alarm && alarm.tag) {
            if (alarm.tag === "device_working" || alarm.tag === "offline") {
              return { ...alarm, category: `${t("platform")}` };
            } else {
              return { ...alarm, category: `${t("process")}` };
            }
          }
          return alarm;
        });
        setAlarms(updatedAlarms);
      } else {
        setAlarms([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const [loadingDevices, setLoadingDevices] = useState<boolean>(true);
  const [devices, setDevices] = useState<any[]>([]);

  useEffect(() => {
    getDevices().then((res: any) => {
      if (res && res.installations) {
        setDevices([...res.installations]);
      }
      setLoadingDevices(false);
    });
  }, []);

  const checkAlarmsTag = (tag: string) => {
    switch (tag) {
      case "quantita_caffe_raggiunta_m1":
        return t("coffeeQuantityGrinder1");
      case "quantita_caffe_raggiunta_m2":
        return t("coffeeQuantityGrinder2");
      case "scoring_macinatore1_low":
        return t("scoringGrinder1Low");
      case "scoring_macinatore2_low":
        return t("scoringGrinder2Low");
      case "scoring_estrazione_low":
        return t("scoringExtractionLow");
      case "scoring_estrazione_high":
        return t("scoringExtractionHigh");
      case "scoring_barista_low":
        return t("scoringBaristaLow");
      case "scoring_idealcup_low":
        return t("scoringIdealCupLow");
      case "quantita_caffe_raggiunta":
        return t("coffeeQuantityReached");
      case "resistenza":
        return t("resistence");
      case "device_working":
        return t("deviceWorking");
      case "device_deleted":
        return t("deviceDeleted");
      default:
        return tag;
    }
  };

  if (loading || loadingDevices) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("selectInterval")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={8}>
        <CustomCard
          actions={[
            <CustomButton
              fullWidth
              type="outlined"
              label={t("reset")}
              onClick={() => {
                setValues({ start_date: "", end_date: "" });
              }}
              confirm
              confirmQuestion={`${t("question")}`}
            />,
            <CustomButton
              fullWidth
              type="contained"
              label={t("ok")}
              onClick={handleSubmit}
            />,
          ]}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: darkColor }}>
          {t("alarms")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("name")}`,
              field: "installation_name",
              flex: 2,
              renderCell: (params: GridRenderCellParams) => {
                const tmp = devices.find(
                  (e) => e.id === params.row.installation_id
                );
                if (admin) {
                  return tmp?.name;
                } else {
                  return (
                    <Link
                      to={dashboardPageUrl(params.row.installation_id)}
                      style={{ color: "black" }}
                    >
                      {tmp?.name}
                    </Link>
                  );
                }
              },
            },
            {
              headerName: `${t("address")}`,
              field: "installation_info",
              flex: 2,
              renderCell: (params: GridRenderCellParams) => {
                const tmp = devices.find(
                  (e) => e.id === params.row.installation_id
                );
                return (
                  <>
                    {tmp?.installation_info?.address},{" "}
                    {tmp?.installation_info?.city}{" "}
                    {tmp?.installation_info?.province}
                  </>
                );
              },
            },
            {
              headerName: `${t("category")}`,
              field: "category",
              flex: 2,
            },
            {
              headerName: `${t("description")}`,
              field: "type",
              flex: 3,
              renderCell: (params: GridRenderCellParams) => (
                <CustomTag
                  label={checkAlarmsTag(
                    String(params.row.tag)
                  ).toLocaleUpperCase()}
                  type="filled"
                  color={
                    params.row.category === `${t("process")}`
                      ? "#FFC736" // giallo
                      : "#F14949" // rosso
                  }
                />
              ),
              filterOperators: [
                {
                  label: `${t("contains")}`,
                  value: "contains",
                  getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) return null;
                    return (row) => {
                      const displayedValue = checkAlarmsTag(
                        String(row.row.tag)
                      ).toLocaleUpperCase();
                      return displayedValue.includes(
                        filterItem.value.toUpperCase()
                      );
                    };
                  },
                  InputComponent: (props) => {
                    const [value, setValue] = React.useState("");
                    const handleChange = (e: any) => {
                      setValue(e.target.value);
                      if (props.applyValue) {
                        props.applyValue({
                          ...props.item,
                          value: e.target.value,
                        });
                      }
                    };
                    return (
                      <TextField
                        value={value}
                        onChange={handleChange}
                        variant="standard"
                        label={`${t("search")}`}
                      />
                    );
                  },
                },
                {
                  label: `${t("equals")}`,
                  value: "equals",
                  getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) return null;
                    return (row) => {
                      const displayedValue = checkAlarmsTag(
                        String(row.row.tag)
                      ).toLocaleUpperCase();
                      return displayedValue === filterItem.value.toUpperCase();
                    };
                  },
                  InputComponent: (props) => {
                    const [value, setValue] = React.useState("");
                    const handleChange = (e: any) => {
                      setValue(e.target.value);
                      if (props.applyValue) {
                        props.applyValue({
                          ...props.item,
                          value: e.target.value,
                        });
                      }
                    };
                    return (
                      <TextField
                        value={value}
                        onChange={handleChange}
                        variant="standard"
                        label={`${t("search")}`}
                      />
                    );
                  },
                },
                {
                  label: `${t("startsWith")}`,
                  value: "startsWith",
                  getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) return null;
                    return (row) => {
                      const displayedValue = checkAlarmsTag(
                        String(row.row.tag)
                      ).toLocaleUpperCase();
                      return displayedValue.startsWith(
                        filterItem.value.toUpperCase()
                      );
                    };
                  },
                  InputComponent: (props) => {
                    const [value, setValue] = React.useState("");
                    const handleChange = (e: any) => {
                      setValue(e.target.value);
                      if (props.applyValue) {
                        props.applyValue({
                          ...props.item,
                          value: e.target.value,
                        });
                      }
                    };
                    return (
                      <TextField
                        value={value}
                        onChange={handleChange}
                        variant="standard"
                        label={`${t("search")}`}
                      />
                    );
                  },
                },
                {
                  label: `${t("endsWith")}`,
                  value: "endsWith",
                  getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) return null;
                    return (row) => {
                      const displayedValue = checkAlarmsTag(
                        String(row.row.tag)
                      ).toLocaleUpperCase();
                      return displayedValue.endsWith(
                        filterItem.value.toUpperCase()
                      );
                    };
                  },
                  InputComponent: (props) => {
                    const [value, setValue] = React.useState("");
                    const handleChange = (e: any) => {
                      setValue(e.target.value);
                      if (props.applyValue) {
                        props.applyValue({
                          ...props.item,
                          value: e.target.value,
                        });
                      }
                    };
                    return (
                      <TextField
                        value={value}
                        onChange={handleChange}
                        variant="standard"
                        label={`${t("search")}`}
                      />
                    );
                  },
                },
                {
                  label: `${t("isEmpty")}`,
                  value: "isEmpty",
                  getApplyFilterFn: (filterItem) => {
                    return (row) => {
                      const displayedValue = checkAlarmsTag(
                        String(row.row.tag)
                      ).toLocaleUpperCase();
                      return displayedValue === "";
                    };
                  },
                  InputComponent: () => null, // Nessun input necessario
                },
                {
                  label: `${t("isNotEmpty")}`,
                  value: "isNotEmpty",
                  getApplyFilterFn: (filterItem) => {
                    return (row) => {
                      const displayedValue = checkAlarmsTag(
                        String(row.row.tag)
                      ).toLocaleUpperCase();
                      return displayedValue !== "";
                    };
                  },
                  InputComponent: () => null, // Nessun input necessario
                },
              ],
            },
            {
              headerName: `${t("start")}`,
              field: "start",
              flex: 2,
              filterable: false,
              renderCell: (params: GridRenderCellParams) => {
                return new Date(params.row.start).toLocaleString();
              },
            },
            {
              headerName: `${t("end")}`,
              field: "finish",
              flex: 2,
              filterable: false,
              renderCell: (params: GridRenderCellParams) => {
                if (params && params.row && params.row.finish) {
                  return new Date(params?.row?.finish).toLocaleString();
                } else {
                  return t("alarmStillActive");
                }
              },
            },
          ]}
          rows={alarms}
        />
      </Grid>
    </Grid>
  );
};

export default Alarm;
